import { Component, Vue } from "vue-property-decorator";
import { ProfileActions } from "@store/modules/profile/types";
import { profileModule } from "@store/namespaces";
import { telegramSupportBotLink } from "@config/social";

@Component
export default class BlockedAccount extends Vue {
  @profileModule.Action("fetchProfile")
  private readonly fetchProfileAction!: ProfileActions["fetchProfile"];

  private get telegramSupportBotLink() {
    return telegramSupportBotLink;
  }

  private mounted() {
    const intervalId = window.setInterval(() => {
      this.fetchProfileAction({ isSaveErrorStatus: true });
    }, 10e3);

    this.$once("hook:beforeDestroy", () => {
      window.clearInterval(intervalId);
    });
  }
}
